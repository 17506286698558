import React from 'react';
import Layout from '../Layout';
import "./volunteer.scss";
import icon1 from "../../images/volunteer-icon-1.png"
import icon2 from "../../images/volunteer-icon-2.png"
import icon3 from "../../images/volunteer-icon-3.png"
import SEO from '../seo';

const VolunteerPage = ({ location }) => {
    return (
        <Layout>
            <SEO
                title="Chinmaya Mission | Volunteer"
                description="Become a Chinmaya Champion of Change to learn the science of crowdfunding and create lasting social impact."
                keywords="Chinmaya Mission, CCMT, Volunteer, Champion of Change, Charitty, fundraising"
                img={`https://${location.host}/link-preview.jpg`}
            />
            <div className='volunteer'>
                <h1>Chinmaya Champions of Change</h1>

                <div className="divider"></div>


                <p>Register and participate virtually from anywhere in the world. <br /><br />
                    We would be delighted to have you join the movement by becoming a Chinmaya Champion of Change. Accelerated impact in multiple areas and lots of fun- without the run!<br /><br />
                    Your participation will enable us to expand the reach of our message and take you on a unique journey of goodness where each contribution adds up to a wonderful whole. <br /><br />
                    This is an opportunity to feel your impact and make a difference to millions of people in need.

                </p>
                <a className='btn' href=""> Learn More</a>
                <div className='volunteer__secondpanel'>
                    <div className='volunteer__secondpanel__content'>
                        <div className='volunteer__card'>
                            <h3>Volunteer Now</h3>
                            <ul>
                                <li>All volunteers receive training in the science of crowdfunding before the start of the campaign</li>
                                <li>Start your own personalized campaign for the Chinmaya Mission with personalized tools for reach</li>
                                <li>Invite your family and friends to add their support for a good cause</li>
                                <li>Spend only 20-30 minutes each day but make a MASSIVE difference to millions</li>
                                <li>Your most powerful tool to succeed is YOU!</li>
                            </ul>
                        </div>
                        Reach out to Richa Sharma at <strong>+91 9953888168 / richa.sharma@chinmayamission.com</strong> or fill in the <a href="/contact/"><strong>contact form here</strong></a> to sign up.
                    </div>
                </div>

                <div className='bottom-panel'><h3>Other ways to participate</h3>
                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <h4>SPONSOR</h4>
                                <img src={icon2}></img>
                                <div className='text'>Chinmaya Champions of Change</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='card'>
                                <h4>SHARE</h4>
                                <img src={icon3}></img>
                                <div className='text'>Mobilize the community and spread the world</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='card'>
                                <h4>SUPPORT</h4>
                                <img src={icon1}></img>
                                <div className='text'>Fundraising campaign details for awareness</div>

                            </div>
                        </div>



                    </div>
                </div>
            </div >
        </Layout >
    );
}

export default VolunteerPage;
